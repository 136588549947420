<template>
  <el-collapse value="Filter" accordion>
    <el-collapse-item title="Filter" name="Filter">
      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthFilter"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doFilter"
        class="filter"
        ref="form"
      >
        <el-row>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.mitarbeiterNr.label"
              :prop="fields.mitarbeiterNr.name"
            >
              <el-input v-model="model[fields.mitarbeiterNr.name]" />
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.vollname.label"
              :prop="fields.vollname.name"
            >
              <el-input v-model="model[fields.vollname.name]" />
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.mandantFilter.label"
              :prop="fields.mandantFilter.name"
            >
              <app-autocomplete-many-input
                :fetchFn="fields.mandantFilter.fetchFn"
                v-model="model[fields.mandantFilter.name]"
              ></app-autocomplete-many-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.kostenstelle.label"
              :prop="fields.kostenstelle.name"
            >
              <app-autocomplete-many-input
                :fetchFn="fields.kostenstelle.fetchFn"
                v-model="model[fields.kostenstelle.name]"
              ></app-autocomplete-many-input>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.verwendungKostenstelle.label"
              :prop="fields.verwendungKostenstelle.name"
            >
              <app-autocomplete-many-input
                :fetchFn="fields.verwendungKostenstelle.fetchFn"
                v-model="model[fields.verwendungKostenstelle.name]"
              ></app-autocomplete-many-input>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.fakultaet.label"
              :prop="fields.fakultaet.name"
            >
              <app-autocomplete-many-input
                :fetchFn="fields.fakultaet.fetchFn"
                v-model="model[fields.fakultaet.name]"
              ></app-autocomplete-many-input>
            </el-form-item>
          </el-col>
          <el-col style="margin-bottom: -0.41px" :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.auswertungForRange.label"
              :prop="fields.auswertungForRange.name"
            >
              <el-date-picker
                :format="'MM.yyyy'"
                v-model="model[fields.auswertungForRange.name]"
                type="monthrange"
                align="right"
                unlink-panels
                range-separator="Bis"
                start-placeholder="Beginn Monat"
                end-placeholder="Ende Monat"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="filter-buttons">
          <el-button
            size="small"
            :disabled="loading"
            @click="doFilter"
            icon="el-icon-fa-search"
            type="primary"
          >
            <app-i18n code="common.process"></app-i18n>
          </el-button>

          <!--           <el-button
            size="small"
            :disabled="loading"
            @click="doResetFilter"
            icon="el-icon-fa-undo"
          >
            <app-i18n code="common.reset"></app-i18n>
          </el-button> -->
        </div>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { AuswertungModel } from '@/modules/auswertung/auswertung-model';

const { fields } = AuswertungModel;

const filterSchema = new FilterSchema([
  fields.id,
  fields.mandantFilter,
  fields.mitarbeiterNr,
  fields.vollname,
  fields.auswertungForRange,
  fields.kostenstelle,
  fields.fakultaet,
  fields.verwendungKostenstelle,
]);

export default {
  name: 'app-auswertung-list-filter',

  props: ['visible', 'tableColNo'],

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
      months: 1,
      pickerOptions: {
        shortcuts: [
          {
            text: 'Dieses Jahr',
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '6 Monate',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setMonth(start.getMonth() + 5);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '12 Monate',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setMonth(start.getMonth() + 11);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '18 Monate',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setMonth(start.getMonth() + 17);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      loading: 'auswertung/list/loading',
      filter: 'auswertung/list/filter',
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    this.model = filterSchema.initialValues(this.filter, this.$route.query);
  },

  methods: {
    ...mapActions({
      doReset: 'auswertung/list/doReset',
      doFetch: 'auswertung/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      filter.months = this.months;

      this.$emit('setMonths', this.months.toString());
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style></style>

<template>
  <div class="app-page-toolbar">
    <el-tooltip :content="exportButtonTooltip" :disabled="!exportButtonTooltip">
      <span>
        <el-button
          :disabled="exportButtonDisabled"
          @click="doExport()"
          icon="el-icon-fa-file-excel-o"
        >
          <app-i18n code="common.export"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-auswertung-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'auswertung/list/hasRows',
      loading: 'auswertung/list/loading',
      exportLoading: 'auswertung/list/exportLoading',
    }),
    exportButtonDisabled() {
      return !this.hasRows || this.loading || this.exportLoading;
    },
    exportButtonTooltip() {
      return !this.hasRows ? i18n('common.noDataToExport') : null;
    },
  },

  methods: {
    ...mapActions({
      doExport: 'auswertung/list/doExport',
    }),

    async doDestroyAllSelected() {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroyAll(this.selectedRows.map((item) => item.id));
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.auswertung.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.auswertung.list.menu"></app-i18n>
      </h1>

      <app-auswertung-list-toolbar></app-auswertung-list-toolbar>
      <app-auswertung-list-filter></app-auswertung-list-filter>
      <app-auswertung-list-table></app-auswertung-list-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AuswertungListFilter from '@/modules/auswertung/components/auswertung-list-filter.vue';
import AuswertungListTable from '@/modules/auswertung/components/auswertung-list-table.vue';
import AuswertungListToolbar from '@/modules/auswertung/components/auswertung-list-toolbar.vue';

export default {
  name: 'app-auswertung-list-page',

  components: {
    [AuswertungListFilter.name]: AuswertungListFilter,
    [AuswertungListTable.name]: AuswertungListTable,
    [AuswertungListToolbar.name]: AuswertungListToolbar,
  },

  async beforeRouteLeave(to, from, next) {
    try {
      this.doReset();
      next();
    } catch (error) {
      next(false);
    }
  },

  methods: {
    ...mapActions({
      doReset: 'auswertung/list/doReset',
    }),
  },
};
</script>

<style></style>
